<template>
	<div class="top-container">
		<Toast />
		<div class="container">
			<div class="row">
				<div class="col-md-8 mx-auto">
					<div class="card-group">
						<div class="card p-4">
							<div class="card-body">
								<div v-if="showError" class="col-12"
									style="position: absolute; left: 0; top: 15px; padding: 0 15px;">
									<div class="alert alert-danger" style="font-size: 14px">
										<p style="margin-bottom: 0;">Wrong login details! Please try again.</p>
									</div>
								</div>
								<div style="height: 50px;"></div>
								<form @submit.prevent="submit">
									<div style="font-size: 32px">Cardiologist Login</div>
									<p class="text-muted">Sign In to your account</p>
									<div class="form-group">
										<div class="input-group mb-3">
											<div class="input-group-prepend">
												<span class="input-group-text">
													<i class="fa fa-user"></i>
												</span>
											</div>
											<input type="text"
												:class="{ 'is-invalid': fcontrol.email?.hasError && fcontrol.email.touched }"
												v-model="fdata.email" @blur="fcontrol.email.touched = true" placeholder="Email"
												required="" class="form-control">
											<div class="invalid-feedback" v-if="fcontrol.email?.hasError">
												<span v-if="fcontrol.email.errors.required">You must enter email address</span>
											</div>
										</div>
									</div>
									<div class="input-group mb-4">
										<div class="input-group-prepend">
											<span class="input-group-text">
												<i class="fa fa-lock"></i>
											</span>
										</div>
										<input type="password"
											:class="{ 'is-invalid': fcontrol.password?.hasError && fcontrol.password.touched }"
											v-model="fdata.password" @blur="fcontrol.password.touched = true" placeholder="Password"
											required="" class="form-control">
										<div class="invalid-feedback" v-if="fcontrol.password?.hasError">
											<span v-if="fcontrol.password.errors.required">You must enter password</span>
										</div>
									</div>
									<div class="row">
										<div class="col-12">
											<div style="padding-top: 20px; float: left;">
												<label>
													<input type="checkbox" v-model="acceptTerms"> I Accept 
												</label>
												<router-link to="/terms-conditions">Terms &amp; Conditions</router-link>
											</div>
											<button :disabled="loading" type="submit"
												class="btn btn-info btn-lg px-4 pull-right">Login</button>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div class="card"
							style="background: rgb(49 146 218 / 92%); padding: 20px; display: flex; align-items: center; flex-direction: row">
							<img style="max-width: 100%; max-height: 100%; display: block" src="../assets/teleradio.png"
								alt="">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Toast from 'primevue/toast';
export default {
	components: {
		Toast
	},
	data() {
		return {
			showError: false,
			loading: false,
			acceptTerms: false,
			fdata: {
				email: '',
				password: '',
			},
			fvalidator: {
				email: 'required',
				password: 'required',
			},

		}
	},
	methods: {
		submit() {
			if (this.fHasError() || this.loading) return;
			if(!this.acceptTerms) {
				this.$toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Please accept terms and conditions to proceed',
					life: 3000
				});
				return;
			}
			this.loading = true;
			this.showError = false;
			this.post("cardiologist_login", this.fdata).then(res => {
				this.loading = false;
				if (!res.success) {
					this.showError = true;
					return;
				}
				window.localStorage.cardiologist_access_token = JSON.stringify(res.data);
				this.$router.replace('/cardiologist');
			});
		}
	},
	beforeRouteEnter(to, from, next) {
		if (window.localStorage.cardiologist_access_token) return next('/cardiologist');
		next();
	}
}
</script>
<style scoped>
.btn-info,
.btn-info:hover {
	background-color: #a1c4c6;
	border-color: #a1c4c6;
}

.top-container {
	display: flex;
	align-items: center;
	min-height: 100vh;
	background: #e4e5e6;
	background-image: url(../assets/cardio.jpg);
	background-size: 100% 100%;
	background-attachment: fixed;
}

.card-group {
	display: flex;
	flex-direction: column;
	box-shadow: -5px 12px 20px -1px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: -5px 12px 20px -1px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: -5px 12px 20px -1px rgba(0, 0, 0, 0.75);
}

@media (min-width: 576px) {
	.card-group {
		flex-flow: row wrap;
	}
}

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid #c8ced3;
	border-radius: .25rem;
}

.input-group-text {
	color: #495057;
}

.input-group-text i {
	line-height: inherit;
}
</style>